import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import Header from './header';
import MobileMenu from './mobile-menu';
import SEO from './SEO';
import Sidebar from './sidebar';
import '../style/components/layout.scss';

export default function Layout({children, context, currentPath, handleNav}) {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const {sidenav, docs} = context;
  const sidebarProps = {nav: sidenav, docs, currentPath, handleNav}

  useEffect(() => {
    insertSDKScript();
    insertScripts();
    insertIntercomWidget();
  }, []);

  function insertSDKScript() {
    const SDK_PROGRAM_ID = 'bca59bd9-171b-4d1f-92af-4b2b7305268a';
    const sdkScript = document.createElement('script');
    sdkScript.src = 'https://resources.fidel.uk/sdk/js/v2/fidel.js';
    sdkScript.className = 'fidel-form';
    const attributes = {
      'data-auto-open': 'false',
      'data-key': 'pk_test_demo',
      'data-program-id': SDK_PROGRAM_ID,
      'data-company-name': 'Fidel',
    };
    Object.keys(attributes).forEach(key => sdkScript.setAttribute(key, attributes[key]));
    document.body.appendChild(sdkScript);
  }

  function insertScripts() {
    const scripts = [
      {src: 'https://secure.leadforensics.com/js/107687.js'},
      {src: 'https://cdn.statuspage.io/se-v2.js'},
      {src: '//js.hs-scripts.com/6061996.js', options: {async: true, defer: true, id: 'hs-script-loader'}},
    ];
    scripts.forEach(script => insertScriptWithSrc(script.src, script.options));
  }

  function insertScriptWithSrc(src, options) {
    const script = document.createElement('script');
    if (options)
      Object.keys(options).forEach(key => {script[key] = options[key]});

    script.src = src;
    document.body.appendChild(script);
  }

  function insertIntercomWidget() {
    const intercomSettingsScript = document.createElement('script');
    intercomSettingsScript.innerHTML = `window.intercomSettings = {
      app_id: 'rvwdo6m2',
      custom_launcher_selector: '.intercom-launcher'
    }`;
    document.body.appendChild(intercomSettingsScript);
    const intercomScript = document.createElement('script');
    /* eslint-disable quotes */
    intercomScript.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/rvwdo6m2';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l(),false);}}})()`;
    /* eslint-enable */
    document.body.appendChild(intercomScript);
  }

  return (
    <div>
      <SEO />
      <Header showMobileMenu={() => {setMobileMenuVisible(true)}} />
      <ReactCSSTransitionGroup
        transitionName="overlay"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {mobileMenuVisible
          ? (
            <MobileMenu
              nav={sidenav}
              currentPath={currentPath}
              handleClose={() => {setMobileMenuVisible(false)}}
              handleNav={handleNav}
            />
          ) : null
        }
      </ReactCSSTransitionGroup>
      <div className="site-wrapper">
        <Sidebar {...sidebarProps} />
        <div className="main-wrapper">
          <main>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  context: PropTypes.object.isRequired,
  currentPath: PropTypes.string.isRequired,
  handleNav: PropTypes.func.isRequired,
};
