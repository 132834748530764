import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { navigate } from 'gatsby';

import NavLink from './navlink';
import SubscribeForm from './subscribe-form';
import createId from '../utils/create-id';
import '../style/components/side-nav.scss';

export default function Sidebar({nav, docs, handleNav, currentPath}) {
  useEffect(() => {
    const path = localStorage.getItem('fidel-docs-path');
    localStorage.removeItem('fidel-docs-path');
    if (path) {
      handleNav({
        target: {dataset: {path}},
        preventDefault: () => {},
      });
    }
  }, []);

  const items = nav.split('\n');
  const renderers = {
    /* eslint-disable react/prop-types */
    link: ({href, children}) => (
      <a onClick={handleNav} href={href} data-path={href}>{children}</a>
    ),
    /* eslint-enable */
  };

  return (
    <div className="side-nav">
      <nav>
        <ul className="nav-list" data-testid="sidenav">
          <NavLink
            className="button button-small button-green"
            to="https://status.fidel.uk"
            label="API Status"
            style={{marginBottom: '20px'}}
          />
          {items.map((item, idx) => {
            if (item) {
              const sections = docs[idx] && (docs[idx].match(/\n\#{1,2} .+/g) || []).map(str => str.substring(4));
              const match = item.match(/\]\(.+\)/)
              const sectionParent = match && match[0] && match[0].substring(3, match[0].length - 1);

              return (
                <li key={item}>
                  <ReactMarkdown source={item} renderers={renderers} />
                  <SubNav
                    parent={sectionParent}
                    items={sections}
                    handleNav={handleNav}
                    current={currentPath}
                  />
                </li>
              );
            }
            return null;
          })}
          <hr />
          <NavLink to="https://releasenotes.fidel.uk" label="Release notes" />
          <NavLink to="https://community.fidel.uk/" label="Developer community" />
        </ul>
      </nav>
      <hr />
      <SubscribeForm />
    </div>
  );
}

function SubNav({parent, items, handleNav, current}) {
  const basePath = parent.length > 0 ? parent : 'home';
  const visible = current ? current.includes(basePath) : basePath === 'home';

  return items
    && (
      <ul className={`nav-list sub-menu-container${visible ? '' : ' hidden'}`}>
        {items.map(sub => {
          const href = `/${basePath}/${createId(sub)}`;
          return (
            <li key={sub} className={`sub-menu-item${href === current ? ' active' : ''}`}>
              <a href={href} data-path={href} onClick={handleNav}>
                {sub}
              </a>
            </li>
          )
        })}
      </ul>
    );
}

Sidebar.propTypes = {
  nav: PropTypes.string.isRequired,
  docs: PropTypes.array.isRequired,
  handleNav: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
};

SubNav.defaultProps = {
  parent: null,
  items: null,
  handleNav: () => {},
};

SubNav.propTypes = {
  current: PropTypes.string.isRequired,
  parent: PropTypes.any,
  items: PropTypes.array,
  handleNav: PropTypes.func,
};
