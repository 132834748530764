import React, { useState } from 'react';
import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';

export default function SubscribeForm() {
  const [email, setEmail] = useState();
  const [timer, setTimer] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    const baseUrl = 'https://ah7v6xpif7.execute-api.eu-west-1.amazonaws.com/production';
    axios.post(`${baseUrl}/subscribe`, {email})
      .then(
        () => {
          if (timer)
            clearTimeout(timer);


          setEmail(null);
          setSuccess(true);

          const input = document.querySelector('.subscribe-form .input');
          input && input.blur();

          const newTimer = setTimeout(() => {setSuccess(false) }, 3500);
          setTimer(newTimer);
        },
        () => {
          const newTimer = setTimeout(() => {setError(false) }, 6000);
          setTimer(newTimer);
          setError(true);
        }
      );
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label>
          Get the latest product updates in your inbox:
          <input
            type="email"
            name="email"
            className="input-small"
            placeholder="you@company.com"
            onChange={e => {setEmail(e.target.value) }}
            data-testid="subscribe-input"
          />
        </label>
        <button type="submit" className="button-small button-primary" data-testid="subscribe-submit">
          Subscribe
        </button>
      </form>
      {success
        ? (
          <p className="subscribe-message success">
            Thanks for subscribing.<br />
            Weʼll be in touch!
          </p>
        ) : null
      }
      {error
        ? (
          <p className="subscribe-message error">
            Something went wrong.<br />
            <a href="mailto:rvwdo6m2@intercom-mail.com" className="intercom-launcher">
              Get in touch
            </a>
          </p>
        ) : null
      }
    </>
  );
}
