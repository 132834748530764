import React from 'react';
import PropTypes from 'prop-types';

export default function NavLink({to, className, label, style}) {
  return (
    <li style={style}>
      <a href={to} className={className} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    </li>
  );
}

NavLink.defaultProps = {
  className: '',
  style: null,
};

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};
