import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import hljs from 'highlight.js';

import FileIcon from '../../static/assets/images/file.svg';

export default function CodeRenderer({ language, value }) {
  const codeRef = useRef();

  useEffect(() => {
    hljs.highlightBlock(codeRef.current);
  }, []);

  function trimFilename(code = '') {
    const start = code.indexOf('fileName:');
    if (start >= 0) {
      const end = code.indexOf('\n', start);
      const filename = code.substring(start + 'fileName:'.length, end);
      code = code.substring(end + 1);
    }

    return code;
  }

  function lineNumbers(value = '') {
    const isShellCode = language && language.includes('sh');
    const rowsClass = isShellCode ? 'line-numbers-rows-shell' : 'line-numbers-rows';
    const match = value.match(/\n(?!$)/g);
    const filenameOffset = value.includes('fileName:') ? 0 : 1;
    const linesNum = match ? match.length : 1;

    return (
      <span className={rowsClass} aria-hidden={true}>
        {new Array(linesNum + filenameOffset).fill('').map((_, idx) => <span key={idx} />)}
      </span>
    );
  }

  return (
    <div className="code-box">
      {value.indexOf('fileName') > -1
        ? (
          <div className="code-block-header">
            <FileIcon />
            {value.substring(9, value.indexOf('\n', 1))}
          </div>
        ) : null
      }
      <pre>
        <code ref={codeRef} className={`language-${language}`}>
          {trimFilename(value)}
        </code>
        {lineNumbers(value)}
      </pre>
    </div>
  );
}

CodeRenderer.defaultProps = {
  language: '',
};

CodeRenderer.propTypes = {
  value: PropTypes.string.isRequired,
  language: PropTypes.string,
};
