export default function createId(text) {
  return text
    .toLowerCase()
    .replace(/^\d\. /, '')
    .replace(/ - /g, '-')
    .replace(/- /g, '-')
    .replace(/ – /g, '-')
    .replace(/ – /g, '-')
    .replace(/ /g, '-')
    .replace(/-$/, '');
}
