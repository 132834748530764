import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import NavLink from './navlink';
import FidelLogo from './assets/fidel_logo.svg';
import '../style/components/header.scss';

export default function Header({showMobileMenu}) {
  const [signedIn, setSignedIn] = useState(false);
  const [accountName, setAccountName] = useState();
  const iframeUrl = 'https://dashboard.fidel.uk';

  useEffect(() => {
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    eventer(
      messageEvent,
      e => {
        const origin = e.origin || e.originalEvent.origin;
        if (origin === iframeUrl && e.data && e.data.userCompany) {
          setSignedIn(true);
          setAccountName(e.data.userCompany);
        }
      },
      false
    );

    const dashboardIFrame = document.getElementById('dashboard-signed-in');
    dashboardIFrame.onerror = () => setSignedIn(false);
    dashboardIFrame.src = `${iframeUrl}/assets/iframe/index-iframe.html`;
  }, []);

  const links = [
    {label: 'API Reference', href: 'https://reference.fidel.uk'},
    {label: 'Help Centre', href: 'https://fidellimited.zendesk.com'},
  ];

  return (
    <header data-testid="header">
      <iframe title="dashboard" id="dashboard-signed-in" style={{display: 'none'}} />
      <div className="header-inner">
        <div className="logo-wrapper">
          <a href="https://fidel.uk" className="logo">
            <FidelLogo />
          </a>
          <Link to="/" className="docs-label">
            Docs
          </Link>
        </div>
        <nav data-testid="nav">
          <ul className="nav-list">
            {links.map(({href, label}) => (
              <NavLink key={href} to={href} label={label} />
            ))}
            {signedIn
              ? (
                <NavLink
                  to="https://dashboard.fidel.uk"
                  label={`Dashboard${accountName && ` (${accountName})`}`}
                />
              ) : (
                <>
                  <NavLink to="https://dashboard.fidel.uk/sign-in" label="Sign in" />
                  <NavLink
                    className="button button-primary rise-on-hover"
                    to="https://dashboard.fidel.uk/sign-up"
                    label="Create account"
                  />
                </>
              )
            }
          </ul>
        </nav>
      </div>
      <button className="toggle-menu-button" type="button" onClick={showMobileMenu}>
        <div className="toggle-menu-hamburger" />
      </button>
    </header>
  );
}

Header.propTypes = {
  showMobileMenu: PropTypes.func.isRequired,
};
