import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import CodeRenderer from '../utils/code-renderer';
import createId from '../utils/create-id';
import '../style/components/docs.scss';
import '../style/components/syntax.scss';

export default function Section({docs, handleNav}) {
  useEffect(() => {
    const headings = document.querySelectorAll('section > h1, section > h2');
    headings.forEach(heading => heading.setAttribute('id', createId(heading.innerText)));

    const contentLinks = document.querySelectorAll('a.content');
    contentLinks.forEach(link => {
      if (link.dataset && link.dataset.hasOwnProperty('path')) {
        link.addEventListener('click', handleNav);
      } else {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
      }
    });
  }, []);

  /* eslint-disable react/prop-types */
  const renderers = {
    link: ({href, children}) => href.match(/^http/) && !href.match(/^http(s)?\:\/\/docs\.fidel\.uk/)
      ? <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
      : <a href={href} onClick={handleNav} data-path={href}>{children}</a>,
    code: CodeRenderer,
  };
  /* eslint-enable */

  return (
    <section>
      <ReactMarkdown
        source={docs}
        renderers={renderers}
        escapeHtml={false}
      />
    </section>
  );
}

Section.propTypes = {
  docs: PropTypes.string.isRequired,
  handleNav: PropTypes.func.isRequired,
};
