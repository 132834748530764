import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import NavLink from './navlink';
import FidelLogo from './assets/fidel_logo.svg';
import '../style/components/mobile-menu.scss';

export default function MobileMenu({nav, currentPath, handleClose, handleNav}) {
  const navItems = nav.split('\n');
  const renderers = {
    /* eslint-disable react/prop-types */
    link: ({href, children}) => (
      <a onClick={handleNav} href={href} data-path={href}>{children}</a>
    ),
    /* eslint-enable */
  };
  const externalLinks = [
    {href: 'https://reference.fidel.uk', label: 'API Reference'},
    {href: 'https://fidellimited.zendesk.com', label: 'Help Centre'},
    {href: 'https://dashboard.fidel.uk', label: 'Dashboard'},
    {href: 'https://releasenotes.fidel.uk', label: 'Release notes'},
  ];

  return (
    <div id="mobile-menu" onClick={handleClose}>
      <div className="header">
        <FidelLogo className="inverted-logo" />
        <div className="close-button" />
      </div>
      <nav>
        <ul className="nav-list">
          {navItems.map(item =>
            <li key={item} className={item.includes(currentPath) ? 'active' : ''}>
              <ReactMarkdown source={item} renderers={renderers} />
            </li>
          )}
          <div className="external-links">
            {externalLinks.map(
              ({href, label}) => <NavLink key={href} to={href} label={label} />
            )}
          </div>
        </ul>
      </nav>
    </div>
  );
}

MobileMenu.propTypes = {
  nav: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleNav: PropTypes.func.isRequired,
};
