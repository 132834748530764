import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import Section from '../components/section';

export default function Docs({pageContext}) {
  const [currentPath, setCurrentPath] = useState('');
  const [navItems, setNavItems] = useState();

  const {docs} = pageContext;

  useEffect(() => {
    const navLinks = document.querySelectorAll('.side-nav .nav-list a');
    setNavItems(navLinks);
    attachScrollListener();

    return () => {
      window.removeEventListener('scroll', toggleHeaderScrolledClass);
      window.removeEventListener('touchmove', toggleHeaderScrolledClass);
    };
  }, []);

  useEffect(() => {
    const main = document.querySelector('.main-wrapper');
    if (main && navItems)
      main.addEventListener('scroll', updatePathOnScroll);
  }, [navItems]);

  function handleNav(e) {
    e.preventDefault();
    const {path} = e.target.dataset;
    if (path) {
      const match = path.match(/[\w-]+$/);
      const id = match && match[0];
      window.setTimeout(() => {
        const target = document.getElementById(id);
        if (target) {
          setCurrentPath(path);
          const scrollAnchor = target.previousElementSibling || target.parentElement;
          scrollAnchor.scrollIntoView();
        } else {
          const main = document.querySelector('.main-wrapper');
          if (main) {main.scrollTop = 0;}
        }
      }, 60);

      if (window.history) {
        window.history.pushState({url: path}, id, path);
      }
    }
  }

  function attachScrollListener() {
    const main = document.querySelector('.main-wrapper');
    const header = document.querySelector('header');
    if (main && header) {
      main.addEventListener('scroll', () => toggleHeaderScrolledClass(main, header));
      main.addEventListener('touchmove', () => toggleHeaderScrolledClass(main, header));
    }
  }

  function toggleHeaderScrolledClass(main, header) {
    header.classList.add('scrolled');
    if (main.scrollTop <= 0) {
      header.classList.remove('scrolled');
    }
  }

  function updatePathOnScroll() {
    navItems.forEach(link => {
      const id = link.href && link.href.match(/[\w-]+$/);
      const el = document.getElementById(id);
      if (el && isVisible(el)) {
        const path = link.dataset && link.dataset.path || link.href;
        setCurrentPath(path);
        window.history.pushState({url: path}, id, path);
      }
    });
  }

  function isVisible(element) {
    if (!element) {return false; }
    const rect = element.getBoundingClientRect();
    const viewHeight = Math.max((document.documentElement ? document.documentElement.clientHeight : 0), window.innerHeight);
    return rect.top < viewHeight / 2 && rect.bottom > viewHeight / 2;
  }

  return (
    <Layout context={pageContext} currentPath={currentPath} handleNav={handleNav}>
      {docs.map((doc, idx) => <Section docs={doc} key={`docs${idx}`} handleNav={handleNav} />)}
    </Layout>
  );
}

Docs.propTypes = {
  pageContext: PropTypes.object.isRequired,
};
