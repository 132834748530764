import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

export default function SEO({title, description, image}) {
  return (
    <Helmet
      lang="en"
      title={title}
      titleTemplate="Fidel API – %s"
      description={description}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: '@FidelHQ',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'twitter:image:src',
          content: image,
        },
        {
          name: 'keywords',
          content: 'API, payment data, transaction API, payments, loyalty',
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  title: 'Documentation',
  description: 'Fidel makes transactional data accessible through a single access point so you can create web and mobile applications using real-time payment data',
  image: 'https://blog.fidel.uk/content/images/2018/08/fidel.png',
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};
